<template>
    <div>
        <div class="banner">
            <el-carousel indicator-position="none" :height="bannerHeight">
                <el-carousel-item v-for="(item,index) in banner_list" :key="index">
                     <el-image class="img" :src="item.img" fit="fill"></el-image>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>
<script>
export default {
    props:['info'],
    data() {
        return{
            banner_list:'',
            fullscreenLoading: false,
            bannerHeight:'30vw',
        }
    },
    methods:{
        handleResize (event) {
            this.fullWidth = document.documentElement.clientWidth
            if(event){
                this.bannerHeight=event.currentTarget.innerHeight/2+'px'
                // console.log(this.bannerHeight)
            }
        }
    },
    mounted(){
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
    },
    created(){
        // 获取顶部图片
		let that=this
        this.$api.news.get_banner_list(this.info).then((res)=>{
            // console.log(res)
            if(res.code==200){
                that.banner_list=res.data
            }
        })
    }
}
</script>
<style scoped lang="scss">
.banner{
    transition: transform 1s;
    .img{
        height:100%
    }
}
::v-deep(.banner .el-carousel__container){
		// height:500px
	}
</style>