import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { message } from '@/components/common/message.js';
import utils from '@/components/common/utils.js';
import '@/style/transition.css' // global css
Vue.prototype.axios=axios
Vue.config.productionTip = false
import api from "./service/api.js";
import smartParse from 'vue-smart-parse';
Vue.use(smartParse)
import '@/components/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import base from '@/components/common/getBase/getBase'
import Print from 'vue-print-nb'
import VueBarcode from 'vue-barcode';
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
Vue.prototype.$api = api;
Vue.prototype.$utils = utils;
Vue.prototype.$message = message;
//转换base64
Vue.prototype.$base = base
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.name) {
	  document.title = to.name
	}
	next()
  })

Vue.use(ElementUI);
new Vue({
  router,
  store,
  Print,
  VueBarcode,
  render: h => h(App)
}).$mount('#app')
