<template>
    <div>
        <div class="process">
            <el-steps :active="contentStage"  align-center>
                <el-step title="已下单" description="">
                    <template slot="icon">
                        <img :src="contentStage==-1?placed:contentStage>0?placed_active:placed">
                    </template>
                </el-step>
                <el-step title="运输中" description="">
                    <template slot="icon">
                        <img :src="contentStage==-1?transit:contentStage>=2?transit_active:transit">
                    </template>
                </el-step>
                <el-step title="已签收" description="">
                    <template slot="icon">
                        <img :src="contentStage==-1?placed:contentStage>=3?placed_active:placed">
                    </template>
                </el-step>
            </el-steps>
        </div>
    </div>
</template>
<script>
export default {
    name:'process',
    props:{
        contentStage:{
            type:[Number,String,Object],
            // default:''
        }
    },
    data() {
        return{
            // contentStage:null,
            placed_active:require('../../../assets/image/placed_active.png'),
            placed:require('../../../assets/image/placed.png'),
            transit:require('../../../assets/image/transit.png'),
            transit_active:require('../../../assets/image/transit_active.png'),
        }
    },
    watch:{
       contentStages(val){
           console.log(val)
            this.contentStage=val
        }
    },
    created(){
        console.log(this.contentStage)
        // this.contentStage=this.order_detail.state
    }
}
</script>
<style scoped lang="scss">
.process{
    width: 100%;
    img{
        width: 40px;
    }
}
::v-deep .el-step__icon.is-text {
  border-radius: 50%;
  border: 0px solid;
  border-color: inherit;
}
</style>