// 导入  axios
import axios from 'axios'
import { Loading, Message } from 'element-ui'
// import router from '../router/index'
// 导入qs 对post请求进行序列化，如果不需要序列化，可以不用导入
// import qs from 'qs'

// axios.defaults.baseURL = 'https://xiangmu3.appdingzhi.xyz/'
axios.defaults.baseURL = 'https://ztwlgyl.com/'
axios.defaults.timeout = 100000;

// post请求头 'Content-Type':'multipart/form-data',
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 携带上token
    // const token = localStorage.getItem('token')
    // 在每次发起请求在请求头上加上token值
    // token && (config.headers.Authorization = token)
    // 在每次请求获取数据之前都开启loading弹窗
    const option = {
      lock: true,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.5)'
    }
    loadings = Loading.service(option)
    return config
      // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
      // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
      // const token = store.state.token;
      // token && (config.headers.Authorization = token);
  },
  error => {
      return Promise.error(error);
  })

  // instance.interceptors.response.use(
  //     // 请求成功
  //     res => res.status === 200 ? Promise.resolve(res) : Promise.reject(res),
  //     // 请求失败
  //     error => {
  //         const { response } = error;
  //         if (response) {
  //             // 请求已发出，但是不在2xx的范围
  //             errorHandle(response.status, response.data.message);
  //             return Promise.reject(response);
  //         } else {
  //             // 处理断网的情况
  //             // eg:请求超时或断网时，更新state的network状态
  //             // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
  //             // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
  //             if (!window.navigator.onLine) {
  //                store.commit('changeNetwork', false);
  //             } else {
  //                 return Promise.reject(error);
  //             }
  //         }
  //     });

/*
 * 设置超时时间和跨域，是否允许携带凭证
 */
axios.defaults.timeout = 500000
axios.defaults.withCredentials = true

/*
 * 设置请求传递数据的格式（具体要看服务器要求什么格式，一般情况不用）
 * 比如 x-www-form-urlencoded
 */

// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
// axios.defaults.transformRequest = data => qs.stringify(data)

// 定义一个变量，用来控制请求时加载提示样式，前提是要全局安装了element-ui
var loadings

/*
 * 设置请求拦截器 客户端发送请求->[ 请求拦截器 ] -> 服务器
 * TOEKN 校验（JWT）, 接收服务器返回的token，存储到vue/本地存储
 */
axios.interceptors.request.use(
  config => {
    // 携带上token
    const token = localStorage.getItem('token')
    // 在每次发起请求在请求头上加上token值
    token && (config.headers.Authorization = token)
    // 在每次请求获取数据之前都开启loading弹窗
    const option = {
      lock: true,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.5)'
    }
    loadings = Loading.service(option)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

/*
 * 响应拦截器
 * 服务器返回信息 -> [拦截器的统一处理] ->客户端js获取到信息
 */
axios.interceptors.request.use(
  config => {
      // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
      // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
      // const token = store.state.token;
      // token && (config.headers.Authorization = token);
      return config;
  },
  error => {
      return Promise.error(error);
  })

axios.interceptors.response.use(
  response => {
    if(response.data.code!=200){
      // Message.error(response.data.message)
      // Message.success(response.data.message)
      
    }
    loadings.close()
    return response.data
  },
  error => {
    console.log('错误')
    loadings.close()
    const { response } = error
    if (response) {
      // 服务器返回了，最起码有结果
      switch (response.status) {
        case 401:
          // 当前请求需要用户验证（一般是未登录）
          // 下次有时间查资料整理此处跳转登录页面
          localStorage.clear()  //清除本地存储
          break
        case 403:
          // 服务器已经理解请求，但是拒绝执行它（一般是token过期）
          // 有时间补充资料来跳转登录界面
          localStorage.removeItem('token')
          break
        case 404:
          // 请求失败，请求所希望得到的资源未被在服务器上发现
          // 可强制跳转到404页面
          Message.error('请求资源不存在！')
          break
        case 500:
          // 服务器异常（一般是业务报的错）
          break
      }
    } else {
      // 服务器连结果都没有返回
      if (!window.navigator.onLine) {
        // 断网处理：可以跳转到断网页面
        return
      }
      return Promise.reject(error)
    }
  }
)
// 导出封装的axios
export default axios