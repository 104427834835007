import fu_empty from '@/components/com/fu-empty/fu-empty.vue'
import process from '@/components/com/process/process.vue'
import swiper from '@/components/com/swiper/swiper.vue'
import swiper_img from '@/components/com/swiper_img/swiper_img.vue'
import table_popup from '@/components/com/table_popup/table_popup.vue'
import order_detail from '@/components/com/order_detail/order_detail.vue'
import Vue from 'vue'
Vue.component('fu-empty',fu_empty)
Vue.component('process',process)
Vue.component('swiper',swiper)
Vue.component('table_popup', table_popup)
Vue.component('order_detail', order_detail)
Vue.component('swiper_img', swiper_img)