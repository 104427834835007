import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo:{}
  },
  getters: {
    // 用户信息
	  userInfo(state) {
	    if (state.userInfo) {
	      return state.userInfo;
	    } else {
	      return null;
	    }
	  },
	  password(state){
		if (state.userInfo) {
			return state.userInfo;
		  } else {
			return null;
		  }
	  }
  },
  mutations: {
	  login(state, provider){
		state.userInfo=provider
		window.localStorage.setItem('userInfo',provider);
	  },
    userInfo(state, provider) {
		// console.log(provider)
		state.userInfo=provider
		window.localStorage.setItem('userInfo',provider);
		},
	password(state, provider){
		// console.log(provider)
		state.password=provider
		window.localStorage.setItem('password',provider);
	},
	forget_password(state){
		state.password = null;
        localStorage.removeItem('password');
	},
    // 初始化APP,获取缓存的token,用户信息等其他缓存内容
		initApp(state) {
		  const _userInfo = localStorage.getItem('USER_INFO');
		//   console.log(_userInfo)
		  if (_userInfo) {
		    state.userInfo = _userInfo;
		    global.userInfo = _userInfo;
		    global.token = _userInfo.token;
		  }
		},
    // 退出登录
		logout(state) {
		  state.userInfo = null;
		//   state.cartList = [];
		//   global.userInfo = null;
		//   global.token = null;
		//   state.verifyAgain = false; // 用户退出时二次验证为false，感觉没必要
		//   state.phone = '';
          localStorage.removeItem('userInfo');
		},

  },
  actions: {
  },
  modules: {
  }
})
