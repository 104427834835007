<template>
    <div>
        <div class="banner">
            <el-carousel indicator-position="none" :height="bannerHeight">
                <el-carousel-item v-for="(item,index) in banner" :key="index" >
                    <el-row>
                        <el-col :span="24">
                            <el-image  class="img" :src="item.picture" fit="fill" ></el-image>
                        </el-col>
                    </el-row>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return{
            banner:'',
            fullscreenLoading: false,
            bannerHeight:'25vw',
            cover:'cover'
        }
    },
    methods:{
        imgLoad(){
            // this.$nextTick(()=>{
            //     this.bannerHeight=this.$ref.bannerHeight[0].height
            //     console.log(this.bannerHeight)
            // })
        },
        handleResize (event) {
            this.fullWidth = document.documentElement.clientWidth
            if(event){
                this.bannerHeight=event.currentTarget.innerHeight/2+'px'
                console.log(this.bannerHeight)
            }
            
            // console.log(event)
        }
    },
    created(){
        this.$api.news.getbanner().then((res)=>{
            this.banner=res.data
        })
    },
    mounted(){
        this.handleResize()
        window.addEventListener('resize', this.handleResize)
        // window.addEventListener('resize',()=>{
        //     console.log(this.$ref.bannerHeight)
        //     this.bannerHeight=this.$ref.bannerHeight[0].height
        // })
    }
}
</script>
<style scoped lang="scss">
.banner{
    width: 100%;
    transition: transform 1s;
    .img{
        // width: 100%;
        height:100%
        // height:500px;
    }
}
// ::v-deep(.banner .el-carousel__container){
//     height:100%;
//     border:1px solid red
// 	}
</style>