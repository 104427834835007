<template>
    <div>
        <div class="send_receive_information">
            <!-- <el-button class="check_detail" @click="stamp()">打印</el-button> -->
            <p class="text-bold" style="margin-bottom:10px">寄收件信息</p>
            <div class="flex flex-wrap justify-between">
                <!-- 选择寄件人 -->
                <div class="fill">
                    <el-button size="mini" @click="choose_send()">选择寄件人</el-button>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>寄件人姓名：</p>
                        <el-input
                            size="small"
                            placeholder="请输入寄件人姓名"
                            v-model="send_form.name">
                        </el-input>
                    </div>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>手机号码：</p>
                        <el-input
                            size="small"
                            placeholder="请输入寄件人手机号码"
                            v-model.number="send_form.phone">
                        </el-input>
                    </div>
                    <div class="flex align-center input">
                        <p><span style="color:#fff">*</span>单位名称：</p>
                        <el-input
                            size="small"
                            placeholder="请输入寄件人单位名称"
                            v-model="send_form.send_company_name">
                        </el-input>
                    </div>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>寄件地址：</p>
                        <el-cascader
                            size="mini"
                            ref="cascader" 
                            style="width:100%"
                            v-model="send_form.address_re"
                            placeholder="请选择省、市、区"
                            :options="address_list"
                            @change="send_choose_city"></el-cascader>
                    </div>
                    <div class="flex align-center input">
                        <p></p>
                        <el-input
                            size="small"
                            placeholder="请输入详细地址"
                            v-model="send_form.address_detail">
                        </el-input>
                    </div>
                    <el-button size="mini" class="btn_Intelligent" @click="brainpower(0)">智能地址填写</el-button>
                </div>
                <!-- 选择收件人 -->
                <div class="fill">
                    <el-button size="mini" @click="choose_get()">选择收件人</el-button>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>收件人姓名：</p>
                        <el-input
                            size="small"
                            placeholder="请输入收件人姓名"
                            v-model="get_form.name">
                        </el-input>
                    </div>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>手机号码：</p>
                        <el-input
                            size="small"
                            placeholder="请输入收件人手机号码"
                            v-model="get_form.phone">
                        </el-input>
                    </div>
                    <div class="flex align-center input">
                        <p><span style="color:#fff">*</span>单位名称：</p>
                        <el-input
                            size="small"
                            placeholder="请输入收件人单位名称"
                            v-model="get_form.re_company_name">
                        </el-input>
                    </div>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>收件地址：</p>
                        <el-cascader
                            size="mini"
                            style="width:100%"
                            v-model="get_form.address_re"
                            placeholder="请选择省、市、区"
                            :options="address_list"
                            ref="cascaderArr"
                            @change="get_choose_city"></el-cascader>
                    </div>
                    <div class="flex align-center input">
                        <p></p>
                        <el-input
                            size="small"
                            placeholder="请输入详细地址"
                            v-model="get_form.address_detail">
                        </el-input>
                    </div>
                    <el-button class="btn_Intelligent" size="mini" @click="brainpower(1)">智能地址填写</el-button>
                </div>
                <!-- 货物信息 -->
                <div class="fill">
                    <p class="text-bold title">货物信息</p>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>货物名称：</p>
                        <el-input
                            size="small"
                            placeholder="请输入货物名称"
                            v-model="cargo_name">
                        </el-input>
                    </div>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>重量(kg)：</p>
                        <el-input
                            size="small"
                            placeholder="请输入货物重量"
                            v-model="cargo_kg">
                        </el-input>
                    </div>
                </div>
                <div class="fill">
                    <p class="text-bold title text-white">货物信息</p>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>货物件数：</p>
                        <el-input
                            size="small"
                            placeholder="请输入货物数量"
                            v-model="cargo_number">
                        </el-input>
                    </div>
                    <div class="flex align-center input">
                        <p>体积(m³)：</p>
                        <el-input
                            size="small"
                            placeholder="请输入货物体积"
                            v-model="cargo_volume">
                        </el-input>
                    </div>
                </div>
                <!-- <div class="fill">
                    <p class="text-bold title">服务信息</p>
                    <div class="checkbox">
                        <el-checkbox v-model="collecting_loans">代收贷款</el-checkbox>
                    </div>
                </div> -->
            </div>
            <!-- <div class="flex flex-wrap justify-between" v-if="collecting_loans">
                <div class="fill" style="margin:0">
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>返款周期：</p>
                        <el-select v-model="period" placeholder="请选择" size="small" style="width:100%">
                            <el-option
                            v-for="item in period_list"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="fill" style="margin:0">
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>代收金额：</p>
                        <el-input
                            size="small"
                            placeholder="（0-1000000元）"
                            v-model="amount_money">
                        </el-input>
                    </div>
                </div>
                <div class="fill_info flex align-center">
                    <p>代收账户：</p>
                    <el-input
                        style="width:100%"
                        size="small"
                        placeholder="请输入代收账户"
                        v-model="account">
                    </el-input>
                </div>
            </div> -->
            <div class="flex flex-wrap justify-between">
                <div class="fill">
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>保价声明：</p>
                        <el-input
                            size="small"
                            placeholder="（0-1000000元）"
                            v-model="statement">
                        </el-input>
                    </div>
                </div>
                <div class="fill">
                    <div class="flex align-center input">
                        <p>客户单号：</p>
                        <el-input
                            size="small"
                            placeholder="客户单号"
                            v-model="delivery">
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap justify-between">
                <!-- 换货方式 -->
                <div class="fill" style="margin:0">
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>换货方式：</p>
                         <el-select v-model="replacement_type" placeholder="请选择" size="small" style="width:100%">
                            <el-option
                            v-for="item in delivery_type_list"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>付款方式：</p>
                        <el-select v-model="pay_type" placeholder="请选择" size="small" style="width:100%" @change="handleIsopenSelect">
                            <el-option
                                v-for="item in price_type"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>客户名称：</p>
                        <el-input
                            size="small"
                            placeholder="请输入客户名称"
                            v-model="customer_code">
                        </el-input>
                    </div>
                </div>
                <div class="fill" style="margin:0">
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>收货方式：</p>
                        <el-select v-model="delivery_type" placeholder="请选择" size="small" style="width:100%">
                            <el-option
                                v-for="item in delivery_way_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>签单服务：</p>
                        <el-select v-model="signing_service" placeholder="请选择" size="small" style="width:100%">
                            <el-option
                                v-for="item in signing_service_list"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex align-center input">
                        <p><span style="color:#F70D33">*</span>业务员编号：</p>
                        <el-input
                            size="small"
                            placeholder="请输入业务员编号"
                            v-model="sale_number">
                        </el-input>
                    </div>
                </div>
                <div class="fill_info flex align-center">
                    <p style="width:14.5%">备注信息：</p>
                    <el-input
                        
                        size="small"
                        placeholder="请输入备注信息"
                        v-model="note">
                    </el-input>
                </div>
                <!-- <div class="fill_btn flex align-center flex-wrap">
                    <p></p>
                    <div class="btn_add" v-for="(i,p) of btn_list" :key="p">
                        <el-button size="mini">{{i.title}}</el-button>
                    </div>
                </div> -->
                <div class="Hint" @click="clear()">温馨提示：下单成功后，客服会与您电话联系，请保持电话畅通！</div>
                <div class="fill" style="width:100%">
                    <div class="checkbox flex align-center">
                        <el-checkbox v-model="agree" style="color:#333">我已立即并同意遵守</el-checkbox>
                        <p style="color:#003B8F;cursor: pointer;" @click="service()">《服务条款》</p>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer submit">
            <el-button  @click="submit_order()">提交订单</el-button>
        </span>
        <el-dialog
            :visible.sync="checkDialog"
            width="40%"
            title="订单详情"
            center>
            <order_detail :order_num="orderNum" :order_table="order_table"></order_detail>
        </el-dialog>
        <!-- 确定按钮 -->
        <el-dialog
            width="30%"
            :visible.sync="innerVisible"
            append-to-body>
            <div class="hint_img">
                <img src="../../../assets/image/placed_active.png" alt="">
            </div>
            <p class="text-center">恭喜您！您已经下单成功！</p>
            <div class="hint_btn flex flex-column">
                <el-button class="back_home" @click="back_home()">返回首页</el-button>
                <el-button class="back_home" @click="stamp()">打印</el-button>
                <el-button class="check_detail" @click="check_detail()">查看详情</el-button>
                
            </div>
        </el-dialog>
        <!-- 选择寄件人 -->
        <el-dialog
            :title="sender?'选择寄件人':'选择收件人'"
            :visible.sync="dialog_sender"
            width="30%"
            append-to-body>
            <div class="choose_send">
                <div class="send_check flex align-center">
                    <div class="el-icon-search"></div>
                    <el-input class="Input" placeholder="请输入昵称/手机号进行搜索" v-model="order" maxlength="12"></el-input>
                    <el-button class="btn" type="primary" size="mini" round @click="query()">查询</el-button>
                </div>
                <div v-if="send_list.length>0" class="scroll_list">
                    <div class="send_list flex justify-between align-center" v-for="(j,i) of send_list" :key="i">
                        <div>
                            <p style="color:#003B8F">{{j.name}}</p>
                            <p>{{j.phone}}</p>
                        </div>
                        <el-button size="mini" @click="choose(j,i)">选择ta</el-button>
                    </div>
                </div>
                <fu-empty v-else></fu-empty>
            </div>
        </el-dialog>
        <!-- 服务条款 -->
        <el-dialog
            width="350px"
            title="服务条款"
            :visible.sync="terms_service"
            append-to-body>
            <div class="service_content" >
                <p v-html="service_content.tk"></p>
            </div>
            <div class="agree_btn">
                <el-button @click="my_agree()">我已阅读并同意</el-button>
            </div>
        </el-dialog>
        <div id="exportableTable" style="display: none;">
            <table border="1" style="border-collapse:collapse;width: 100%;text-align: center;">
                <tr rowspan="2">
                  <th colspan="2" style="height:60px">收件人信息</th>
                </tr>
                <tr cospan="2">
                    <!-- 表头 -->
                    <th rowspan="1" style="width:130px;height:60px">收件人</th>
                    <th rowspan="1">{{formData.re_username}}</th>
                </tr>
                <tr cospan="2">
                    <th rowspan="1" style="width:130px;height:60px">电话</th>
                    <th rowspan="1">{{formData.re_phone}}</th>
                </tr>
                 <tr cospan="2">
                    <th rowspan="1" style="width:130px;height:60px">单位</th>
                    <th rowspan="1">{{formData.re_company_name}}</th>
                </tr>
                 <tr cospan="2"> 
                    <th rowspan="1" style="width:130px;height:60px">收货地址</th>
                    <th rowspan="1">{{formData.re_address}}</th>
                </tr>
                <tr cospan="2">
                    <th rowspan="1" style="width:130px;height:60px">运单号</th>
                    <th rowspan="1">{{formData.customer_no}}</th>
                </tr>
                 <tr cospan="2">
                    <th rowspan="1" style="width:130px;height:60px">货物名称</th>
                    <th rowspan="1">{{formData.goods_name}}</th>
                </tr>
                <tr cospan="2">
                    <th rowspan="1" style="width:130px;height:60px">件数</th>
                    <th rowspan="1">{{formData.goods_num}}</th>
                </tr>
                <tr cospan="2">
                    <th rowspan="1" style="width:130px;height:60px">重量</th>
                    <th rowspan="1">{{formData.goods_weight}}(kg)</th>
                </tr>
                <tr cospan="2">
                    <th rowspan="1" style="width:130px;height:60px">体积</th>
                    <th rowspan="1">{{formData.goods_volume}}</th>
                </tr>
                <tr cospan="2">
                    <th rowspan="1" style="width:130px;height:60px">保价</th>
                    <th rowspan="1">{{formData.ex_price}}</th>
                </tr>
                <tr cospan="2">
                    <th rowspan="1" style="width:130px;height:60px">签单服务</th>
                    <th rowspan="1">{{formData.signing_serv}}</th>
                </tr>
                <tr cospan="2">
                    <th rowspan="1" style="width:130px;height:60px">收货方式</th>
                    <th rowspan="1">{{formData.re_goods_mode}}</th>
                </tr>
                <tr cospan="2">
                    <th rowspan="1" style="width:130px;height:60px">备注信息</th>
                    <th rowspan="1">{{formData.remarks}}</th>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import { regionData,CodeToText,TextToCode  } from 'element-china-area-data'
import { mapState, mapActions } from "vuex";
import { location } from "../../common/LocationUtil";
import myBMap from "@/util/getUserLocation"
// import BMap from 'BMap'
export default {
    computed: {
      ...mapState({
        userInfo:state=>state.userInfo
      })
    },
    data() {
        return{
            formData:{},
            order_table:true,
            btn_list:[
				{title:'请携带纸箱'},
				{title:'请带胶带'},
				{title:'需爬楼梯'},
				{title:'来前电联'},
			],
			Dialog_order:false,
            send_form:{
                name:'',
                phone:'',
                address_re:'',
                send_company_name:'',
                address_detail:'',
                province_l:''
            },
            get_form:{
                name:'',
                phone:'',
                address_re:'',
                re_company_name:'',
                address_detail:'',
                province_l:''
            },
            // row:{provice:'浙江省', city:'杭州市', area:'上城区' },
			address_list:regionData,
			cargo_name:'',//货物信息
			cargo_number:'',
			cargo_kg:'',
			cargo_volume:'',
			//collecting_loans:true,//代收贷款
			period:'',
			period_list:[{
                label: 'T+0',
                value: 'T+0',
            },{
                label: 'T+1',
                value: 'T+1',
            },{
                label: 'T+3',
                value: 'T+3',
            }],
			amount_money:'',
			account:'',
			statement:'',
			replacement_type:'',
			replacement_list:[],
			pay_type:"",//付款方式
			price_type:[{
                label: '现金',
                value:'现金'
            },{
                label: '到付',
                value:'到付'
            },{
                label: '月结',
                value:'月结'
            }],
			customer_code:'',
            delivery_type_list:[{//换货方式
                label: '上门接货',
                value:'上门接货'
            },{
                label: '客户接送',
                value:'客户接送'
            }],
			delivery_type:'',//收货方式
			delivery_way_list:[{
                label: '送货(不含上楼)',
                value:'送货(不含上楼)'
            },{
                label: '送货',
                value:'送货'
            },{
                label: '自提',
                value:'自提'
            }],
            
			signing_service:'',//签单服务
			signing_service_list:[{
                label: '无需返单',
                value: '无需返单',
            },{
                label: '签单',
                value: '签单',
            },{
                label: '签单和客户原单',
                value: '签单和客户原单',
            }],
			sale_number:'',//业务员编号
			note:'',//备注信息
			agree:true,
			innerVisible:false,
			dialog_sender:false,//选择寄件人弹窗
			send_list:[],
            terms_service:false,
            order:'',
            sender:false,
            service_content:'',//服务条款
            TextToCode:CodeToText,
            checkDialog:false,
            orderNum:'',
            delivery:'',//送货单号
            info:""
        }
    },
    methods:{
        clear(){
            this.Input_clear()
        },
        handleIsopenSelect(){
            this.$forceUpdate()
        },
        // 打印
        stamp(){
            const printContent = document.getElementById("exportableTable");
            // window.open() 弹出窗口
            const WindowPrt = window.open('', '', 'left=500px,top=500px,width=900,height=900,toolbar=0,scrollbars=0,status=0');//弹出一个空的窗口（设置好宽高
            // write()方法用于写入文档内容，可以传多个参数，写入的字符串会按HTML解析
            WindowPrt.document.write(printContent.innerHTML);
            // 运行document.write()，运行完后，最好手动关闭文档写入document.close()
            WindowPrt.document.close();
            //方法将焦点设置到当前窗口，也就是将窗口显示在前（靠近屏幕）
            WindowPrt.focus();
            // 调用 print() 方法会产生一个打印预览弹框，让用户可以设置打印请求。
            WindowPrt.print();
            // window.close()关闭浏览器窗口
            // WindowPrt.close();
            this.innerVisible=false
        },
        query(){
           let data={
               token:this.userInfo.token,
               keyword:this.order,
               type:this.sender?1:2
           }
            this.$api.news.get_comm_rec(data).then((res)=>{
                // console.log(res)
				if(res.code==200){
					this.send_list=res.data
				}
            })
        },
        choose(e,index){
        //    console.log(e)
            if(this.sender==1){
                this.send_form=e
                 let addressStr=e.address_re
                 this.send_addressStr=addressStr.join(",")
                this.send_form.address_re=TextToCode[addressStr[0]][addressStr[1]][addressStr[2]].code;
            }else{
                this.get_form=e
                 let addressStr=e.address_re
                 this.get_addressStr=addressStr.join(",")
                this.get_form.address_re=TextToCode[addressStr[0]][addressStr[1]][addressStr[2]].code;
            }
            // console.log(this.send_form)
            this.dialog_sender=false
        },
        //城市选择
		send_choose_city(value){
            if(value){
                this.send_form.province_l=CodeToText[value[0]]+','+CodeToText[value[1]]+','+CodeToText[value[2]]
                // console.log(this.send_form.province_l)
            }
		},
        get_choose_city(value){
            this.get_form.province_l=CodeToText[value[0]]+','+CodeToText[value[1]]+','+CodeToText[value[2]]
            // console.log(this.get_form.province_l)
		},
        // 返款周期
		handle_period(){

		},
        // 选择寄件人
		choose_send(){
			this.dialog_sender=true
			this.sender=true
            this.send_list=[]
            this.order=""
            this.query()
		},
		// 选择收件人
		choose_get(){
			this.dialog_sender=true
			this.sender=false
            this.send_list=[]
            this.order=""
            this.query()
		},
        // 我已阅读并同意
        my_agree(){
            this.terms_service=false
            this.agree=true
        },
        handleClose(done){
           done()
		},
        // 收货方式
		Changedelivery(){

		},
		// 签单服务
		Changesigning(){

		},
        // 换货方式
		handlereplacement(){

		},
        // 服务条款
        service(){
            this.$api.news.fuwutk().then((res)=>{
				if(res.code==200){
                    console.log(res)
					this.terms_service=true
                    this.service_content=res.data
				}
            })
        },
		//提交订单
		submit_order(){
            if(!this.userInfo){
                this.$message('请登录')
                setTimeout(()=>{
                    this.$router.push('/login/login')
                },1500)
               
                return
            }
            if(!this.send_form.name|| !this.send_form.phone||!this.send_form.province_l&&!this.send_addressStr||!this.send_form.address_detail){
                this.$message('请将寄件人信息填写完整')
                return
            }
            if(!this.get_form.name|| !this.get_form.phone||!this.get_form.province_l&&!this.get_addressStr||!this.get_form.address_detail){
                this.$message('请将收件人信息填写完整')
                return
            }
            if(!this.cargo_name||!this.cargo_number||!this.cargo_kg||!this.statement||!this.replacement_type||!this.delivery_type||!this.pay_type||!this.signing_service||!this.customer_code||!this.sale_number){
                 this.$message('请将信息填写完整')
                 return
            }
            if(!this.agree){
                this.$message('请阅读并同意服务条款')
                return
            }
            if(this.send_form.province_l){
                this.send_address=this.send_form.province_l+this.send_form.address_detail
            }else{
                this.send_address=this.send_addressStr+this.send_form.address_detail
            }
            if(this.get_form.province_l){
                this.re_address=this.get_form.province_l+this.get_form.address_detail
            }else{
                this.re_address=this.get_addressStr+this.get_form.address_detail
            }
			let data={
                token:this.userInfo.token,
                send_username:this.send_form.name,//寄件人姓名 address_re
                send_phone:this.send_form.phone,
                send_company_name:this.send_form.send_company_name,
                // send_address:this.send_form.province_l||this.send_addressStr+this.send_form.address_detail,
                send_address:this.send_address,
                re_username:this.get_form.name,//收件人姓名
                re_phone:this.get_form.phone,
                re_company_name:this.get_form.re_company_name,
                // re_address:this.get_form.province_l||this.get_addressStr+this.get_form.address_detail,
                re_address:this.re_address,
                goods_name:this.cargo_name,//货物名称
                goods_num:this.cargo_number,
                goods_weight:this.cargo_kg,
                goods_volume:this.cargo_volume,
                ex_price:this.statement,
                se_goods_mode:this.replacement_type,//换货方式
                re_goods_mode:this.delivery_type,//收获方式，
                pay_mode:this.pay_type,
                signing_serv:this.signing_service,
                cus_code:this.customer_code,// 客户代码
                bus_code:this.sale_number,
                remarks:this.note,
                is_collection:this.collecting_loans==true?1:0,//0不代收 1代收
                //rebate:this.period,//返款周期
                //collect_price:this.amount_money,
                //collect_account:this.account,//代收账户
                from:this.send_form.province_l||this.send_addressStr,
                to:this.get_form.province_l||this.get_addressStr,
                from_ress:this.send_form.address_detail,
                to_ress:this.get_form.address_detail,
                customer_no:this.delivery//客户单号
            }
            console.log(data)
            // return
            this.$api.news.onorder(data).then((res)=>{
                // console.log(res)
				if(res.code==200){
					this.innerVisible=true
                    this.orderNum=res.data.order_num 
                    this.Input_clear()
                    let date={
                        order_num:this.orderNum,
                    }
                    this.$api.news.get_order_byno(date).then((result)=>{
                        // console.log(res)
                        if(result.code==200){
                            this.formData=result.data
                            // console.log(this.formData)
                        }
                    })
				}
            })
		},
        // 返回首页
		back_home(){
			this.innerVisible=false
            this.$emit('close_table',false)
		},
        // 查看详情
		check_detail(){
            this.innerVisible=false
            // this.checkDialog=true
            this.$emit('close',this.orderNum)
		},
        Input_clear(){
            this.$refs.cascader.checkedValue = []
            this.$refs.cascaderArr.checkedValue = []
            this.send_form.name=''
            this.send_form.phone=''
            this.send_form.send_company_name=''
            this.send_form.address_detail=''
            this.get_form.name=''
            this.get_form.phone=''
            this.get_form.re_company_name=''
            this.get_form.address_detail=''
            this.cargo_name=''
			this.cargo_number=''
			this.cargo_kg=''
			this.cargo_volume=''
            this.period='',
			this.period_list=this.period_list
            this.amount_money='',
			this.account='',
			this.statement='',
			this.replacement_type='',
			this.replacement_list=[],
			this.pay_type='',//付款方式
			this.price_type=this.price_type
            this.customer_code='',
            this.delivery_type_list=this.delivery_type_list,
            this.delivery_type='',//收货方式
			this.delivery_way_list=this.delivery_way_list
            this.signing_service='',//签单服务
			this.signing_service_list=this.signing_service_list,
            this.sale_number='',//业务员编号
			this.note='',
            this.delivery=''//送货单号
        },
    
    brainpower(i){
        let that=this
        navigator.clipboard.readText().then((v) => {
            if(v){
                that.message=v
                console.log(this.smartParse(this.message));
                that.textAdd = this.smartParse(this.message);  //识别到的地址对象
                if(i==0){
                    // 寄件人人 智能填写地址
                    that.send_form.name=that.textAdd.name
                    that.send_form.phone=that.textAdd.phone
                    that.send_form.address_detail=that.textAdd.street==undefined?that.textAdd.address:that.textAdd.street+that.textAdd.address
                    let addressStr=[that.textAdd.province,this.textAdd.city,that.textAdd.county]
                    that.send_addressStr=addressStr.join(",")
                    that.send_form.address_re=TextToCode[addressStr[0]][addressStr[1]][addressStr[2]].code;
                    this.send_form.province_l=''
                }else{
                    // 收货人 智能填写地址
                    that.get_form.name=that.textAdd.name
                    that.get_form.phone=that.textAdd.phone
                    that.get_form.address_detail=that.textAdd.street==undefined?that.textAdd.address:that.textAdd.street+that.textAdd.address
                    let addressStr=[that.textAdd.province,that.textAdd.city,that.textAdd.county]
                    that.get_addressStr=addressStr.join(",")
                    that.get_form.address_re=TextToCode[addressStr[0]][addressStr[1]][addressStr[2]].code;
                    this.get_form.province_l=''
                }
            }
        })
        .catch((v) => {
            this.$message('获取剪贴板失败')
        });
    },
    // getlocation_detail(){
    //     let _this=this
    //      myBMap.init().then(BMap => {
    //             let myCity = new BMap.LocalCity()
    //                 myCity.get(
    //                 result => {
    //                     let geoc = new BMap.Geocoder();
    //                     geoc.getLocation(result.center, res => {
    //                         // console.log(res)
    //                         let data={
    //                             location: res.point.lat+','+res.point.lng,
    //                             token:this.userInfo.token,
    //                         }
    //                         this.$api.news.address_info(data).then((result)=>{
    //                             // console.log(result)
    //                             if(result.code==200){
    //                             _this.send_form.address_re=result.data.adcode
    //                             _this.send_form.address_detail=result.data.address_component.street_number
    //                             }
    //                         })
    //                     });
    //                 },
    //                 { enableHighAccuracy: true }
    //                 );
    //         });
    //     }
    },
}
</script>
<style scoped lang="scss">
.send_receive_information{
    margin-top:20px;
    .text-bold{
        font-size: 14px;
    }
    .fill{
        width: 45%;
        // margin-top:20px;
        .title{
            margin:10px 0 0;
        }
        .input{
            margin-top:10px;
            p{
                width: 40%;
            }
        }
        .btn_Intelligent{
            margin-top:20px
        }
        .checkbox{
            margin-top:10px
        }
    }
    .fill_info{
        width: 100%;
        margin-top:10px;
        p{
            width: 100px;
        }
    }
    .fill_btn{
        // width: 100%;
        margin-left: 95px;
        .btn_add{
            margin-top: 10px;
            margin-right:10px
        }
    }
    .Hint{
        margin-top:20px;
        color:#F70D33
    }
}
.send_check{
    background: #F4F4F4;
    border-radius: 20px;
    padding: 0 10px;
}
.scroll_list{
    max-height: 400px;
    overflow-y: scroll;
    .send_list{
        width: 90%;
        margin:auto;
        line-height: 25px;
        padding:10px 0;
        border-bottom:1px solid #eee;
        font-size: 12px;
    }
}

::v-deep(.send_list .el-button){
    background:#003B8F;
    color:#fff;
    border-radius: 50px;
}
::v-deep(.submit .el-button){
    background: #003B8F !important;
    width: 50%;
    color:#fff
}
::v-deep(.fill .el-checkbox__input.is-checked+.el-checkbox__label){
        color:#333
    }
::v-deep(.fill .input .el-input__inner) {
    background-color: #F4F4F4;
}
::v-deep(.fill_info  .el-input__inner) {
    background-color: #F4F4F4;
}
::v-deep(.fill .el-button){
    background: #003B8F;
    color:#fff
}
::v-deep(.send_check .el-input__inner){
    background: #F4F4F4;
}
::v-deep(.send_check .el-button){
    background: #003B8F;
    color:#fff
}
.submit .el-button{
    display: block;
    margin:20px auto 0;
}
.hint_img{
    padding:20px;
    border-radius: 50px;
    background: #eee;
    width: 60px;
    height:60px;
    display: flex;
    align-items: center;
    margin:0 auto 20px;
    img{
        width: 50px;
        margin:auto;
        display: block;
    }
}
.hint_btn{
    .el-button{
        width: 70%;
        margin:10px auto 0
    }
}
.agree_btn{
    width: 70%;
    margin:10px auto 0;
    .el-button{
        width: 100%;
    }
}
.row{
    width: 100px;
    height:100px
}
::v-deep(.agree_btn .el-button){
    background: #003B8F;
    color:#fff
}
::v-deep(.hint_btn .el-button:first-child,.agree_btn .el-button){
    background: #003B8F;
    color:#fff
}
::v-deep(.hint_btn .el-button:last-child){
    border:1px solid #003B8F;
    color:#003B8F
}
::v-deep(.hint_btn .el-button:nth-child(2)){
    border:1px solid #003B8F;
    color:#003B8F
}
</style>