const message={
    success(params) {
        this.$message({
            message:params,
            type: 'success'
        });
        return true
    },
}
export {
    message
};