<template>
    <div>
        <el-empty :image="img"></el-empty>
    </div>
</template>
<script>
export default {
    data() {
        return{
            img:require('../../../assets/img/empty.png')
        }
    },
}
</script>