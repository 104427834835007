<template>
    <div>
        <!-- <process :contentStage="state"></process> -->
        <div v-if="order_table">
            <div class="process">
                <el-steps :active="contentStage"  align-center>
                    <el-step title="已下单" description="">
                        <i slot="icon">
                            <el-image :src="contentStage==-1?placed:contentStage>0?placed_active:placed"></el-image>
                        </i>
                    </el-step>
                    <el-step title="运输中" description="">
                        <i slot="icon">
                            <el-image :src="contentStage==-1?transit:contentStage>=2?transit_active:transit"></el-image>
                        </i>
                    </el-step>
                    <el-step title="已签收" description="">
                        <i slot="icon">
                            <el-image :src="contentStage==-1?placed:contentStage>=3?placed_active:placed"></el-image>
                        </i>
                    </el-step>
                </el-steps>
            </div>
            <div id="printContent">
                <el-descriptions class="margin-top descriptions" title="" :column="2" :size="size">
                    <el-descriptions-item label="订单编号">{{order_detail.order_num}}</el-descriptions-item>
                    <el-descriptions-item label="下单时间">{{order_detail.ctime}}</el-descriptions-item>
                    <el-descriptions-item label="运单编号">{{order_detail.ship_num}}</el-descriptions-item>
                    <el-descriptions-item label="运单状态">{{order_detail.state==0?'在途':order_detail.state==1?'揽收':order_detail.state==2?'疑难':order_detail.state==3?'签收':order_detail.state==4?'退签':order_detail.state==5?'派件':order_detail.state==8?'清关':'拒签'}}</el-descriptions-item>
                    <el-descriptions-item label="寄件人">{{order_detail.send_username}}</el-descriptions-item>
                    <el-descriptions-item label="收件人">{{order_detail.re_username}}</el-descriptions-item>
                    <el-descriptions-item label="手机号码">{{order_detail.send_phone}}</el-descriptions-item>
                    <el-descriptions-item label="手机号码">{{order_detail.re_phone}}</el-descriptions-item>
                    <el-descriptions-item label="寄件地址">{{order_detail.send_address}}</el-descriptions-item>
                    <el-descriptions-item label="收件地址">{{order_detail.re_address}}</el-descriptions-item>
                    <el-descriptions-item label="货物名称">{{order_detail.goods_name}}</el-descriptions-item>
                    <el-descriptions-item label="货物件数">{{order_detail.goods_num}}</el-descriptions-item>
                    <el-descriptions-item label="货物重量">{{order_detail.goods_weight}}KG</el-descriptions-item>
                    <el-descriptions-item label="收货方式">{{order_detail.re_goods_mode}}</el-descriptions-item>
                    <el-descriptions-item label="付款方式">{{order_detail.pay_mode}}</el-descriptions-item>
                    <el-descriptions-item label="签单服务">{{order_detail.signing_serv}}</el-descriptions-item>
                    <el-descriptions-item label="备注">{{order_detail.remarks}}</el-descriptions-item>
                </el-descriptions>
            </div>
            <!-- <table :columns="columns" :data-source="data" bordered id="printMe"></table> -->
            <!-- <button v-print="printContent" class="btn no-print">打印</button> -->
            <!-- <button class="print" v-print="printObj.id">打印</button> -->
        </div>
        <div v-else>
            <div class="block" v-if="order_detail.onway">
                <el-timeline>
                    <el-timeline-item
                    v-for="(activity, index) in order_detail.onway"
                    :key="index"
                    :icon="activity.icon"
                    :type="activity.type"
                    :color="activity.color"
                    :size="activity.size"
                    :timestamp="activity.time">
                    {{activity.context}}
                    </el-timeline-item>
                </el-timeline>
            </div>
            <!-- 空数据 -->
            <div v-else>
                <fu-empty></fu-empty>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        order_num:{
            type:[String,Number],
            default:''
        },
        order_table:{
            type:[Boolean],
            default:''
        }
    },
    data() {
       return{
        //    printObj:{
        //        id:'printContent',
        //        popTitle:'订单信息'
        //    },
           size:"",
           order_detail:'',
           express_info:'',
           contentStage:null,
           placed_active:require('../../../assets/image/placed_active.png'),
            placed:require('../../../assets/image/placed.png'),
            transit:require('../../../assets/image/transit.png'),
            transit_active:require('../../../assets/image/transit_active.png'),
       } 
    },
    methods:{
        
    },
    watch:{
        order_num(val){
            // console.log(val)
            let data={
                order_num:val,
            }
            console.log(data)
            this.$api.news.get_order_byno(data).then((res)=>{
                console.log(res)
                if(res.code==200){
                    this.order_detail=res.data
                    this.contentStage=JSON.parse(res.data.state)
                }
            })
        }
    },
    created(){
        let data={
                order_num:this.order_num,
            }
            // console.log(data)
            this.$api.news.get_order_byno(data).then((res)=>{
                console.log(res)
                if(res.code==200){
                    this.order_detail=res.data
                    this.express_info=res.data.onway
                    this.contentStage=JSON.parse(res.data.state)
                }
            })
    }
}
</script>
<style scoped lang="scss">
.descriptions{
    width: 74%;
    margin:20px auto 0
    
}
.el-step__icon .el-image{
    width: 40px !important;
}
.el-descriptions{
	color:#333
}
.print{
    width: 60%;
    margin:20px auto 0;
    line-height: 40px;
    background: #003B8F;
    display: block;
    border:0;
    color:#fff
}
</style>