<template>
  <div id="app">
       <router-view ></router-view>
  </div>
</template>
<script>
export default {
 created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem('store'))
        )
      )
    }
 
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
}
</script>
<style lang="scss">
/* .element::-webkit-scrollbar { width: 0 !important } */
::-webkit-scrollbar{
    width : 0px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 5px;
  }
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  /* box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2); */
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #ddd;
}
::-webkit-scrollbar-thumb:hover{
  background   : #999;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px #f5f5f5;
  border-radius: 10px;
  background   : #ededed;
}
/*火狐*/
::-moz-scrollbar{
    width : 0px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 5px;
  }
::-moz-scrollbar{
  /*滚动条里面小方块*/
  border-radius: 10px;
  /* box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2); */
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #ddd;
}
::-moz-scrollbar:hover{
  background   : #999;
}
::-moz-scrollbar{
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px #f5f5f5;
  border-radius: 10px;
  background   : #ededed;
}
/*IE*/
::-ms-scrollbar{
    width : 0px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 5px;
}
::-ms-scrollbar:hover{
  background   : #999;
}
::-ms-scrollbar{
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px #f5f5f5;
  border-radius: 10px;
  background   : #ededed;
}
::-ms-scrollbar{
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #ddd;
}
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none; 
}
#nprogress .bar {
  background-color: rgb(64, 158, 255) !important;
}
@import './style/style.css';
</style>
