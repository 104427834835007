import axios from "./service";    
const news={
    sendsms(data){//发送短信
        return axios.post("api/user/sendsms",data)
    },
    regist(data){//注册
        return axios.post("api/user/regist",data);
    },
    login(data){//登录
        return axios.post("api/user/login",data);
    },
    seekpaw(data){//找回密码
        return axios.post("api/user/seekpaw",data);
    },
    getbanner(data){
        return axios.post('api/user/getbanner', data)
    },
    onorder(data){//下单接口
        return axios.post('api/user/onorder', data)
    },
    fuwutk(data){//服务条款 
        return axios.post('api/user/fuwutk', data)
    },
    get_order_byno(data){//获取单个订单详情
        return axios.post('api/user/get_order_byno', data)
    },
    get_order(data){//获取用户订单详情 
        return axios.post('api/user/get_order', data)
    },
    cancel_order(data){//取消订单
        return axios.post('api/user/cancel_order', data)
    },
    industry(data){//获取行业列表
        return axios.post('api/user/industry', data)
    },
    logistics(data){//获取现代项目物流 
        return axios.post('api/user/logistics', data)
    },
    getnewslist(data){//新闻资讯
        return axios.post('api/user/getnewslist', data)
    },
    partner(data){//行业伙伴
        return axios.post('api/user/partner', data)
    },
    product_r(data){//海运 
        return axios.post('api/user/product_r', data)
    },
    get_product_detail(data){//海运 详情 
        return axios.post('api/user/get_product_detail', data)
    },
    get_product_trade(data){//行业案例 封面
        return axios.post('api/user/trade', data)
    },
    get_product_insight(data){//行业洞察
        return axios.post('api/user/insight', data)
    },
    get_anli_list(data){//行业案例
        return axios.post('api/banner/get_anli_list', data)
    },
    get_anli_article_list(data){//行业案例
        return axios.post('api/banner/get_anli_article_list', data)
    },
    get_anli_info(data){//行业案例详情
        return axios.post('api/banner/get_anli_info', data)
    },
    get_comm_rec(data){//获取常用寄件人/收件人
        return axios.post('api/user/get_comm_rec', data)
    },
    get_recruit(data){//获取招聘信息  
        return axios.post('api/user/recruit', data)
    },
    get_order_comm(data){//公共订单查询 
        return axios.post('api/user/get_order_comm', data)
    },
    get_enterprise(data){//获取企业订阅号 联系我门
        return axios.post('api/user/enterprise', data)
    },
    get_editpaw(data){//修改密码
        return axios.post('api/user/editpaw', data)
    },
    get_edituinfo(data){//修改个人资料
        return axios.post('api/user/edituinfo', data)
    },
    get_User(data){//获取用户信息 
        return axios.post('api/user/get_user_info', data)
    },
    seekpaw(data){//找回密码
        return axios.post('api/user/seekpaw', data)
    },
    uploads(data){//找回密码 get_address_info
        return axios.post('api/user/uploads', data)
    },
    address_info(data){
        return axios.post('api/user/get_address_info', data)
    },
    upload(data){
        return axios.post('api/user/importExcel', data)
    },
    // 获取首页视频
    getVideo(data){
        return axios.post('api/banner/get_video_info', data)
    },
    // 产品服务 详情内容
    product_info(data){
        return axios.post('api/banner/get_product_serve_info', data)
    },
    // 首页 新闻资讯轮播 getnewsdetail
    get_banner_new_list(data){
        return axios.post('api/banner/get_banner_new_list', data)
    },
    // 新闻资讯详情 
    getnewsdetail(data){
        return axios.post('api/user/getnewsdetail', data)
    },
    // 首页 项目核心能力
    get_center_ability_list(data){
        return axios.post('api/banner/get_center_ability_list', data)
    },//
    //企业概括 三项展示
    get_survey_list(data){
        return axios.post('api/banner/get_company_survey_list', data)
    },
    //绿色物流—新能源货车
    get_green_info(data){
        return axios.post('api/banner/get_green_logistics_info', data)
    },
    // 行业案例-行业案例
    get_join_list(data){
        return axios.post('api/banner/get_join_us_list', data)
    },
    get_contact_us_list(data){
        return axios.post('api/banner/get_contact_us_list', data)
    },
    // 各模块图片
    get_banner_list(data){
        return axios.post('api/banner/get_model_banner_list', data)
    },
    // 批量导出
    exprot_excel(data){
        return axios.post('api/user/exportExcel', data)
    }
}
export default news;