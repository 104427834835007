function logout(){
	const _userInfo = localStorage.getItem('userInfo');
	// console.log(_userInfo)
	if(!_userInfo){
		localStorage.removeItem('userInfo')
		sessionStorage.clear(); 
		window.localStorage.clear();
		return false
	}
}
module.exports = {
    logout,
  }