import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}
NProgress.configure({     
	easing: 'ease',  // 动画方式    
	speed: 500,  // 递增进度条的速度    
	showSpinner: false, // 是否显示加载ico    
	trickleSpeed: 200, // 自动递增间隔    
	minimum: 0.3 // 初始化时的最小百分比
})

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
	redirect: '/main',
    component: () => import('../views/Home'),
	children:[{
		path: '/main',
		name: '首页',
		component: () => import('../views/main')
      },{
			path: '/navIndex/product',
			name: '产品服务',
			component: () => import('../views/navIndex/product'),
			meta: { title: '产品服务', noCache: true }
      },{
			path: '/navIndex/seaIndex',
			name: '产品服务',
			component: () => import('../views/navIndex/seaIndex'),
			meta: { title: '产品服务', noCache: true }
      },{
			path: '/navIndex/airIndex',
			name: '产品服务',
			component: () => import('../views/navIndex/airIndex'),
			meta: { title: '产品服务', noCache: true }
      },{
			path: '/navIndex/soluIndex',
			name: '产品服务',
			component: () => import('../views/navIndex/soluIndex'),
			meta: { title: '产品服务', noCache: true }
      },{
			path: '/navIndex/multIndex',
			name: '产品服务',
			component: () => import('../views/navIndex/multIndex'),
			meta: { title: '产品服务', noCache: true }
      },{
			path: '/navIndex/storIndex',
			name: '产品服务',
			component: () => import('../views/navIndex/storIndex'),
			meta: { title: '产品服务', noCache: true }
      },{
			path: '/navIndex/landIndex',
			name: '产品服务',
			component: () => import('../views/navIndex/landIndex'),
			meta: { title: '产品服务', noCache: true }
      },{
			path: '/navIndex/trainIndex',
			name: '产品服务',
			component: () => import('../views/navIndex/trainIndex'),
			meta: { title: '产品服务', noCache: true }
		},{
			path: '/navIndex/customer_service',
			name: '订单查询',
			component: () => import('../views/navIndex/customer_service')
		},{
			path: '/navIndex/news_bulletin',
			name: '企业新闻',
			component: () => import('../views/navIndex/news_bulletin')
		  
		},{
			path: '/navIndex/video_bulletin',
			name: '企业视频',
			component: () => import('../views/navIndex/video_bulletin')
		  
		},{
			path: '/navIndex/applications',
			name: '行业案例',
			component: () => import('../views/navIndex/applications')
		
		},{
			path: '/Applications/fast_moving',
			name: '快速消费品行业',
			component: () => import('../views/Applications/fast_moving')
		
		},{
			path: '/Applications/message',
			name: '电子信息制造行业',
			component: () => import('../views/Applications/message')
		
		},{
			path: '/Applications/parts_cars',
			name: '汽车及零部件行业',
			component: () => import('../views/Applications/parts_cars')
		
		},{
			path: '/Applications/intelligent',
			name: '智能制造装备行业',
			component: () => import('../views/Applications/intelligent')
		
		},{
			path: '/Applications/precision',
			name: '精密仪器行业',
			component: () => import('../views/Applications/precision')
		
		},{
			path: '/Applications/medical',
			name: '医疗器械行业',
			component: () => import('../views/Applications/medical')
		
		},{
			path: '/Info/Info_detail',
			name: '详情',
			component: () => import('../views/Info/Info_detail')
		
		},{
			path: '/mine/management',
			name: '修改密码',
			component: () => import('../views/mine/management')
		
		},{
			path: '/mine/firm_summary',
			name: '企业概括',
			component: () => import('../views/mine/firm_summary')
		
		},{
			path: '/mine/contact_us',
			name: '联系我们',
			component: () => import('../views/mine/contact_us')
		
		},{
			path: '/mine/qualification',
			name: '资质荣誉',
			component: () => import('../views/mine/qualification')
		
		},{
			path: '/mine/enterprise',
			name: '企业招聘',
			component: () => import('../views/mine/enterprise')
		
		},{
			path: '/mine/set_personal',
			name: '账号管理',
			component: () => import('../views/mine/set_personal')
		
		},{
			path: '/mine/my_order',
			name: '我的订单',
			component: () => import('../views/mine/my_order')
		},{
			path: '/mine/send_service',
			name: '在线下单',
			component: () => import('../views/mine/send_service')
		},{
			path: '/Info/newDetail',
			name: '新闻资讯',
			component: () => import('../views/Info/newDetail')
		}]
  },{
	path: '/',
    name: 'login',
	redirect: '/login/login',
    component: () => import('../views/login/login'),
	children:[{
		path: '/login/login',
		name: '用户登录',
		component: () => import('../views/login/login')
	}]
  }
  
]
const router = new VueRouter({
  routes
})
router.beforeEach((to, from , next) => {
	// 每次切换页面时，调用进度条
   NProgress.start();
 　　// 若加载时间长且不定，担心进度条走完都没有加载完，可以调用
　　NProgress.inc(),//这会以随机数量递增，且永远达不到100%，也可以设指定增量
   next();
});
//当路由进入后：关闭进度条
router.afterEach(() => {  
   // 在即将进入新的页面组件前，关闭掉进度条
   NProgress.done()
})
export default router
